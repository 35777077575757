/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */
import React from "react";
import FormFader from "../../Common/Fader/FormFader";
import wizardStyle from "../../wizardStyle";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import FormLegend from "../FormLegend/FormLegend";
import { Link } from "react-router-dom";

export default function RecordingConsentSuccess() {
  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Grid container justifyContent={"center"} alignItems={"baseline"}>
          <Grid item xs={12}>
            <FormLegend>
              <strong>Vielen Dank für Ihre Auswahl</strong>
            </FormLegend>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ textAlign: "center", my: "2rem" }}>Sie können Ihre Entscheidung jederzeit in Ihrer Servicewelt ändern.</Typography>
          </Grid>
          <Grid item sx={{ paddingTop: "1rem", paddingBottom: "2rem" }} xs={12} sm={6}>
            <Button fullWidth variant={"contained"} component={Link} to={"/servicewelt"}>
              Zurück zur Servicewelt
            </Button>
          </Grid>
        </Grid>
      </FormFader>
    </Paper>
  );
}
