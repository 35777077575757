import React, { useEffect, useRef, useState } from "react";
import serviceWorldCaseStyle from "./serviceWorldCaseStyle";
import { Helmet } from "react-helmet-async";
import StatusBox from "./StatusBox/StatusBox";
import ProcessBox from "./ProcessBox/ProcessBox";
import { getProductData, getProductNameBy } from "../../services/productService";
import { Link, useLocation } from "react-router-dom";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import DocumentBox from "../PetitionSummary/DocumentBox";
import ServiceWorldCaseDetails from "./ServiceWorldCase/ServiceWorldCaseDetails";
import ServiceWorldCaseProductBoxes from "./ServiceWorldCase/ServiceWorldCaseProductBoxes";
import { Box, Button, Grid } from "@mui/material";
import PageNavigation from "../Navigation/SummaryNavigation/PageNavigation";
import ServiceWorldCaseProductGuidebookBoxes from "./ServiceWorldCase/ServiceWorldCaseProductGuidebookBoxes";
import { gaSet } from "../../services/cookieService";
import CalendlyAppointment from "../Calendly/CalendlyAppointment";
import { getAppointmentDisplayType } from "../../services/serviceWorldService";
import CustomerFeedbackBox from "./Common/CustomerFeedbackBox";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, fetchResource, queryKeys } from "../../services/reactQuery/reactQueryService";
import ServiceWorldFileUploadLinkBox from "./ServiceWorldFileUploadLinkBox";
import useProductParams from "../../hooks/useProductParams";
import { useCustomer } from "../../provider/Customer/CustomerProvider";
import InquiriesToCustomerCaseBox from "./InquiryToCustomer/InquiriesToCustomerCaseBox";
import { InquiryToCustomerStatus } from "../../types/Entities/InquiryToCustomer";
import { WarningAmberOutlined } from "@mui/icons-material";
import NewMessageCaseBox from "./Common/NewMessageCaseBox";
import ServiceWorldCaseDelete from "./ServiceWorldCaseDelete";
import { StatusBoxDeactivatedProduct } from "./StatusBox/StatusBoxDeactivatedProduct";
import { getInquiriesBoxHeadingContent } from "../../services/inquiryToCustomerService";

const ServiceWorldCase = () => {
  const { productName, productId } = useProductParams();
  const productstyle = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productstyle, "apiUri");
  const { data: product, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const { customer } = useCustomer();
  const location = useLocation();
  const [caseIsDeletable, setCaseIsDeletable] = useState(null);

  const inquiryFilters = {
    product: productstyle,
    productId: productId,
    "status[0]": InquiryToCustomerStatus.STATUS_OPEN,
    "status[1]": InquiryToCustomerStatus.STATUS_OVERDUE,
  };
  const inquiryApiUri = "/inquiry_to_customers";
  const { data: inquiries } = useQuery(queryKeys.collection(inquiryApiUri, inquiryFilters), () => fetchCollection(inquiryApiUri, inquiryFilters));

  let refs = {
    "#upload": useRef(),
    "#documents": useRef(),
    "#details": useRef(),
    "#appointments": useRef(),
    "#inquiry": useRef(),
    "#deleteCase": useRef(),
  };

  useEffect(() => {
    if (location.hash && refs[location.hash] && refs[location.hash].current) {
      refs[location.hash].current.scrollIntoView();
    }
  }, [product?.id]);

  const title = "Servicewelt Mein Fall | Legalbird";
  gaSet({ title: title });

  if (isLoading) {
    return (
      <Grid container justifyContent={"center"} sx={serviceWorldCaseStyle.loaderContainer}>
        <Grid item>
          <LegalbirdLoader />
        </Grid>
      </Grid>
    );
  }

  let navigationLinks = [
    {
      target: "#status",
      label: "Jetzt vervollständigen",
    },
  ];

  if (product.isCustomerDataCompleted) {
    navigationLinks = [
      {
        target: "#status",
        label: "Status prüfen",
      },
      {
        target: "#documents",
        label: "Dokumente einsehen",
      },
    ];
  }

  if (getProductData(product.name, "mediaObjectUploadAllowed")({ product })) {
    navigationLinks.push({
      target: "#upload",
      label: "Dokument hochladen",
    });
  }

  if (getAppointmentDisplayType(product) === "appointments") {
    navigationLinks.unshift({
      target: "#appointments",
      label: "Mein Beratungstermin",
    });
  }

  if (inquiries && inquiries.length > 0) {
    navigationLinks.unshift({
      target: "#inquiry",
      label: getInquiriesBoxHeadingContent(inquiries).headline,
      icon: <WarningAmberOutlined />,
    });
  }

  if (caseIsDeletable) {
    navigationLinks.push({
      target: "#deleteCase",
      label: "Fall löschen",
    });
  }

  let tertiaryHeadline = null;
  if (product.responsibleLawyer) {
    tertiaryHeadline = (product.responsibleLawyer.gender === "male" ? "Ihr Anwalt: " : "Ihre Anwältin: ") + product.responsibleLawyer.fullName;
  }

  return (
    <Box sx={serviceWorldCaseStyle.contentContainer}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageNavigation
        navigationLinks={navigationLinks}
        headerIcon={getProductData(product.name, "productIcon")}
        headline={getProductData(product.name, "label")}
        subHeadline={product.reference}
        tertiaryHeadline={tertiaryHeadline}
      />
      <Box sx={serviceWorldCaseStyle.scrollToAnchor} id={"inquiry"} ref={refs["#inquiry"]} />
      {inquiries && inquiries.length > 0 && <InquiriesToCustomerCaseBox product={product} inquiries={inquiries} />}
      <Box sx={serviceWorldCaseStyle.scrollToAnchor} id={"appointments"} ref={refs["#appointments"]} />
      <CalendlyAppointment product={product} customer={customer} type={"advisoryAppointment"} />
      <CalendlyAppointment product={product} customer={customer} type={"lawyerCounselingCall"} />
      <Box sx={serviceWorldCaseStyle.scrollToAnchor} id={"status"} ref={refs["#status"]} />
      {product.isDeactivatedProduct ? <StatusBoxDeactivatedProduct /> : <StatusBox product={product} />}
      <CustomerFeedbackBox product={product} />
      <NewMessageCaseBox product={product} />
      <ProcessBox product={product} />
      <ServiceWorldCaseProductBoxes product={product} />
      <Box sx={serviceWorldCaseStyle.scrollToAnchor} id={"documents"} ref={refs["#documents"]} />
      <DocumentBox product={product} />
      <Box sx={serviceWorldCaseStyle.scrollToAnchor} id={"details"} ref={refs["#details"]} />
      <ServiceWorldCaseDetails product={product} />
      <Box sx={serviceWorldCaseStyle.scrollToAnchor} id={"upload"} ref={refs["#upload"]} />
      <ServiceWorldFileUploadLinkBox product={product} />
      <ServiceWorldCaseProductGuidebookBoxes product={product} />
      <Box sx={serviceWorldCaseStyle.scrollToAnchor} id={"deleteCase"} ref={refs["#deleteCase"]} />
      <ServiceWorldCaseDelete product={product} setCaseIsDeletable={setCaseIsDeletable} caseIsDeletable={caseIsDeletable} />
      <Button fullWidth color="buttonGrey" component={Link} variant={"contained"} to={"/servicewelt"}>
        Zurück zur Servicewelt
      </Button>
    </Box>
  );
};

export default ServiceWorldCase;
