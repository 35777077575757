import React from "react";
import calendlyStyle from "./calendlyStyle";
import _get from "lodash/get";
import _includes from "lodash/includes";
import ContentBox from "../Box/ContentBox/ContentBox";
import callIcon from "../../assets/icons/generic/IconCallClock";
import moment from "moment";
import { Button, Divider, Link } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { Customer } from "../../types/Entities/Customer";
import Typography from "@mui/material/Typography";
import { AbstractCase } from "../../types/Entities/AbstractCase";
import { getCaseLink } from "../../services/productService";

type CalendlyAppointmentProps = {
  product: AbstractCase;
  customer: Customer;
  type: string;
};

const CalendlyAppointment = ({ product, customer, type }: CalendlyAppointmentProps) => {
  const calendlyData = _get(product, type);

  if (!_get(calendlyData, "appointment") || !customer || _includes(["processed", "not_processed"], _get(calendlyData, "status"))) {
    return null;
  }

  const getConsentUrl = () => {
    return getCaseLink(product) + "/zustimmung_gespraechsaufzeichnung/" + calendlyData["calendlyEventId"];
  };

  return (
    <ContentBox headline={"Mein Beratungstermin"} icon={callIcon}>
      <div>
        Sie haben einen Termin für den {moment(calendlyData.appointment.date).format("DD.MM.YYYY")} um {moment(calendlyData.appointment.date).format("HH:mm")}{" "}
        Uhr gebucht und werden unter der Nummer {customer.telephone} angerufen.
      </div>
      <Button component={Link} href={calendlyData.rescheduleUrl} target="_blank" sx={calendlyStyle.appointmentBoxbutton} color={"primary"} fullWidth>
        Termin verschieben
      </Button>
      <Button component={Link} href={calendlyData.cancelUrl} target="_blank" sx={calendlyStyle.appointmentBoxbutton} color={"primary"} fullWidth>
        Termin absagen
      </Button>
      <Button component={HashLink} to={"/servicewelt/mein-konto#personalData"} sx={calendlyStyle.appointmentBoxbutton} color={"primary"} fullWidth>
        Telefonnummer ändern
      </Button>
      {type === "lawyerCounselingCall" && (
        <>
          <Divider />
          <Typography variant={"h5"} component={"h3"} sx={{ textAlign: "center" }}>
            Gesprächszusammenfassung
          </Typography>
          {calendlyData.recordingConsent &&
            calendlyData.recordingConsent.consentGiver === null &&
            calendlyData.recordingConsent.approvalDate === null &&
            calendlyData.recordingConsent.denialDate === null && (
              <Typography>Wünschen Sie eine Zusammenfassung Ihres Beratungsgesprächs? Dann geben Sie uns hier Ihre Einverständniserklärung ab.</Typography>
            )}
          {calendlyData.recordingConsent && calendlyData.recordingConsent.approvalDate !== null && (
            <Typography>
              Sie haben dem Mitschnitt und der Zusammenfassung des Beratungsgesprächs zugestimmt. Sollten Sie Ihr Einverständnis widerrufen wollen, klicken Sie
              bitte auf “Einverständnis verwalten”
            </Typography>
          )}
          {calendlyData.recordingConsent && calendlyData.recordingConsent.denialDate !== null && (
            <Typography>
              Sie haben dem Mitschnitt und der Zusammenfassung des Beratungsgesprächs widersprochen. Sollten Sie doch einen Mitschnitt wünschen, klicken Sie
              bitte auf “Einverständnis verwalten”
            </Typography>
          )}
          <Button component={Link} href={getConsentUrl()} target="_blank" sx={calendlyStyle.appointmentBoxbutton} color={"primary"} fullWidth>
            Einverständnis verwalten
          </Button>
        </>
      )}
    </ContentBox>
  );
};

export default CalendlyAppointment;
